import React from "react";
import PropTypes from "prop-types";
import { ImageTile } from "@gonoodle/gn-universe-ui";
import { useRouter } from "next/router";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";

import { WithContentLink } from "../../containers";
import { ROUTE_PREFIX } from "../../constants";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

const TileWithLink = WithContentLink(ImageTile);

const TextTile = React.forwardRef(function TextTile(
  { id, slug, name, description, tile, title, format, linkParams },
  forwardedRef,
) {
  const router = useRouter();
  let customTagtitle = "";
  let customTagDescription = "";
  if (format === "landscape") {
    customTagtitle = title;
    customTagDescription = description;
  }

  return (
    <>
      <TileWithLink
        alt={name}
        name={name}
        title={customTagtitle}
        sources={tile}
        format={format}
        description={customTagDescription}
        id={id}
        slug={slug}
        contentLinkAsBase={`/${ROUTE_PREFIX.TAGS}`}
        contentLinkHrefBase={`/${ROUTE_PREFIX.TAGS}/[...identity]`}
        linkParams={linkParams}
        events={[
          {
            event: "Link Clicked",
            properties: {
              sourceElement: linkParams?.sourceElement,
              sourceName: linkParams?.sourceName,
              sourcePage: linkParams?.sourcePage,
              sourcePageType: linkParams?.sourcePageType,
              title: name,
              ...getUtmParamsFromQueryParams(router.query),
            },
          },
        ]}
        ref={forwardedRef}
      />
      <span className="sr-only absolute left-1/4 uppercase text-white bottom-0">
        {name}
      </span>
    </>
  );
});

export default TextTile;

TextTile.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tile: ImageSourcesType,
};
