import React from "react";
import PropTypes from "prop-types";
import { AgeGatedPressable, ImageTile } from "@gonoodle/gn-universe-ui";
import { useRouter } from "next/router";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";

import { Link } from "../../containers";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";
import { useCurriculum } from "../../hooks";
import {
  requiresAgeGate,
  transformSuperNoodleMarketingUrlToCurriculum,
} from "../../utils/superNoodleMarketingUrl";
import { URLS } from "../../constants";
import api from "../../api";

export default function CustomLinkCardTile({
  sources,
  title = "",
  description,
  linkParams = {},
  format = "landscape",
  customLinkTitle,
  destinationUrl,
}) {
  const router = useRouter();
  const curriculum = useCurriculum();
  const client = api();

  const superNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
    destinationUrl,
    curriculum,
  );
  const showAgeGate = requiresAgeGate(destinationUrl, curriculum);

  const eventProperties = {
    sourceElement: linkParams?.sourceElement,
    sourceName: linkParams?.sourceName,
    sourcePage: linkParams?.sourcePage,
    sourcePageType: linkParams?.sourcePageType,
    title,
  };

  const customLinkDestinationUrl = superNoodleRoute || destinationUrl;

  return showAgeGate ? (
    <AgeGatedPressable
      className="text-left"
      onPress={() =>
        client
          .trackAnalyticsEvents({
            event: "Link Clicked",
            properties: eventProperties,
          })
          .finally(() => router.push(URLS.SUPERNOODLE_URL))
      }
    >
      <ImageTile
        alt={title}
        title={customLinkTitle}
        format={format}
        description={description}
        sources={sources}
      />
    </AgeGatedPressable>
  ) : (
    <Link
      events={[
        {
          event: "Link Clicked",
          properties: {
            ...eventProperties,
            url: customLinkDestinationUrl,
            ...getUtmParamsFromQueryParams(router.query),
          },
        },
      ]}
      href={customLinkDestinationUrl}
    >
      <ImageTile
        alt={title}
        title={customLinkTitle}
        format={format}
        description={description}
        sources={sources}
      />
    </Link>
  );
}

CustomLinkCardTile.propTypes = {
  title: PropTypes.string,
  sources: ImageSourcesType.isRequired,
  description: PropTypes.string.isRequired,
  linkParams: PropTypes.shape({
    sourceElement: PropTypes.string,
    sourceName: PropTypes.string,
    sourcePage: PropTypes.string,
    sourcePageType: PropTypes.string,
  }),
  format: PropTypes.oneOf(["landscape", "square", "portrait"]),
  customLinkTitle: PropTypes.string.isRequired,
  destinationUrl: PropTypes.string.isRequired,
};
